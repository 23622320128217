






































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { Button, Card, Datepicker, Form, Select, TextField, genders, preferredContacts, FormErrors, yesNo, states, isEmail, isValidPassword, postcodeRule, isPhoneNumber, PreferredContactMethod } from '@focus/components'
import { RespondentRegisterData } from '../auth.types'
import TermsAndConditionsCheckbox from '@/domains/core/components/TermsAndConditionsCheckbox.vue'

@Component({
  components: { Button, Card, TermsAndConditionsCheckbox, Datepicker, Form, Select, TextField }
})
export default class Register extends Vue {
  @Prop({ type: Object, default: () => ({}) })
  private readonly errors!: FormErrors<RespondentRegisterData>

  account: Partial<RespondentRegisterData> = {
    preferredContact: PreferredContactMethod.EMAIL,
    haveChildren: false
  }

  get yesNo () {
    return yesNo
  }

  get preferredContacts () {
    return preferredContacts
  }

  get states () {
    return states
  }

  get genders () {
    return genders
  }

  get form () {
    return this.$refs.form as Form
  }

  isValidPassword (val: string) {
    return isValidPassword(val)
  }

  sameAsPassword (val: string) {
    return val === this.account.password || 'Passwords must match'
  }

  isEmail (val: string) {
    return isEmail(val)
  }

  phoneRule (val: string) {
    return !val || isPhoneNumber(val)
  }

  postcodeRule (val: string) {
    return postcodeRule(val)
  }

  async register () {
    if (this.form.validate()) {
      this.$emit('register', this.account)
    }
  }
}









import { Component } from 'vue-property-decorator'

import { Modal, Datepicker, ApiError } from '@focus/components'
import { LoginDetails } from '@/domains/auth'
import BaseComponent from '@/plugins/mixins'

@Component({
  components: { Modal, Datepicker }
})
export default class DOBLoginModal extends BaseComponent {
  private isOpen = false

  private data: Partial<LoginDetails> = {}

  public open (details?: Partial<LoginDetails>) {
    this.data = { ...details }
    this.isOpen = true
  }

  public close () {
    this.data = {}
    this.isOpen = false
    this.$emit('close')
  }

  async attemptLogin () {
    try {
      await this.$store.dispatch('auth/login', this.data)
      this.close()
    } catch (error) {
      if (error instanceof ApiError) {
        if (error.statusCode === 401) {
          await this.showError(new ApiError({ code: 'LOGIN', message: 'Invalid login details' }))
        } else {
          await this.showError(error)
        }
      } else {
        throw error
      }
    }
  }
}

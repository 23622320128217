

















import { Component } from 'vue-property-decorator'
import { ApiError, Button, Card, Form, TextField } from '@focus/components'

import NewPasswordError from '../components/NewPasswordError.vue'
import NewPasswordForm from '../components/NewPasswordForm.vue'
import NewPasswordSuccess from '../components/NewPasswordSuccess.vue'
import BaseComponent from '@/plugins/mixins'

@Component({
  components: { Button, Card, Form, NewPasswordError, NewPasswordForm, NewPasswordSuccess, TextField }
})
export default class NewPasswordPage extends BaseComponent {
  mounted () {
    this.checkToken()
  }

  passwordReset = false

  tokenValidated = false

  get token () {
    return this.$route.query.token
  }

  get error () {
    if (this.$store.state.auth.error?.code !== 'SAME_PASSWORD') {
      return this.$store.state.auth.error
    } else {
      return null
    }
  }

  get formError () {
    if (this.$store.state.auth.error?.code === 'SAME_PASSWORD') {
      return this.$store.state.auth.error
    } else {
      return null
    }
  }

  get loading () {
    return this.$store.state.auth.isLoading
  }

  async confirmPassword (data: { password: string }) {
    try {
      await this.$store.dispatch('auth/resetPassword', { password: data.password, token: this.token })
      this.passwordReset = true
    } catch (error) {
      if (error instanceof ApiError) {
        await this.showError(error)
      } else {
        throw error
      }
    }
  }

  async checkToken () {
    if (!this.token) {
      this.tokenValidated = true
    }
    try {
      await this.$store.dispatch('auth/checkToken', { token: this.token })
    } catch (error) {
      if (error instanceof ApiError) {
        await this.showError(error)
      } else {
        throw error
      }
    } finally {
      this.tokenValidated = true
    }
  }
}

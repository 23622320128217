
































import { LoginDetails } from '..'
import { Component } from 'vue-property-decorator'
import { ApiError, Button, Card, Form, TextField, VuetifyForm } from '@focus/components'
import BaseComponent from '@/plugins/mixins'

import DOBLoginModal from '@/domains/auth/components/DOBLoginModal.vue'

@Component({
  components: { Button, Card, Form, TextField, DOBLoginModal }
})
export default class LoginComponent extends BaseComponent {
  data: Partial<LoginDetails> = {}

  get loading () {
    return this.$store.state.auth.isLoading
  }

  get form () {
    return this.$refs.form as VuetifyForm
  }

  get dobModal () {
    return this.$refs.dobModal as DOBLoginModal
  }

  get cardStyles () {
    return this.$vuetify.breakpoint.xsOnly ? 'width: 100%' : 'width: 560px'
  }

  get registerPage () {
    return { name: 'register', query: this.$route.query }
  }

  async login () {
    if (this.form.validate()) {
      try {
        await this.$store.dispatch('auth/login', this.data)
        this.navigateUser()
      } catch (error) {
        if (error instanceof ApiError) {
          if (error.statusCode === 401) {
            await this.showError(new ApiError({ code: 'LOGIN', message: 'Invalid login details' }))
          } else if (error.statusCode === 422) {
            this.dobModal.open(this.data)
          } else {
            await this.showError(error)
          }
        } else {
          throw error
        }
      }
    }
  }

  navigateUser () {
    const { redirect, ...query } = this.$route.query
    const formattedRedirect = typeof redirect === 'string' ? redirect : undefined
    if (formattedRedirect) {
      this.$router.push({ path: formattedRedirect, query })
    } else {
      this.$router.push({ path: '/home' })
    }
  }
}

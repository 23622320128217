














































import { Component } from 'vue-property-decorator'
import { ApiError, Button, Card } from '@focus/components'
import BaseComponent from '../../../plugins/mixins'

@Component({
  components: { Button, Card }
})
export default class EmailConfirmPage extends BaseComponent {
  success = false

  loading = true

  slotNumber: number = Number.NaN

  get token () {
    return this.$route.query.token
  }

  async mounted () {
    if (this.token) {
      try {
        const data = await this.$store.dispatch('auth/confirmEmail', { token: this.token })
        this.slotNumber = data.slotNumber
        this.success = true
      } catch (error) {
        if (error instanceof ApiError) {
          await this.showError(error)
        } else {
          throw error
        }
      } finally {
        this.loading = false
      }
    } else {
      this.success = false
      this.loading = false
    }
  }
}









































import { Component } from 'vue-property-decorator'
import { ApiError, Button, Card, formatValidationError, FormErrors } from '@focus/components'

import Register from '../components/Register.vue'
import GuestRegister from '../components/GuestRegister.vue'
import RegisterSuccessCard from '../components/RegisterSuccess.vue'
import { GuestRegisterData, RespondentRegisterData } from '../auth.types'
import BaseComponent from '@/plugins/mixins'

@Component({
  components: { Button, Card, Register, GuestRegister, RegisterSuccessCard }
})
export default class RegisterPage extends BaseComponent {
  registerSuccessful = false

  mode: 'guest' | 'respondent' = 'respondent'

  get errors (): FormErrors<RespondentRegisterData> {
    const error = this.$store.state.auth.error
    if (error?.code === 'VALIDATION_FAILED') {
      return formatValidationError(error)
    } else if (error?.code === 'PASSWORD_STRENGTH') {
      return { password: error }
    }
    return {}
  }

  async register (data: Partial<RespondentRegisterData>) {
    try {
      await this.$store.dispatch('auth/register', data)
      this.registerSuccessful = true
    } catch (error) {
      if (error instanceof ApiError) {
        await this.showError(error)
      } else {
        throw error
      }
    }
  }

  async guestRegister (data: Partial<GuestRegisterData>) {
    try {
      await this.$store.dispatch('auth/guestRegister', data)
      this.registerSuccessful = true
    } catch (error) {
      if (error instanceof ApiError) {
        await this.showError(error)
      } else {
        throw error
      }
    }
  }

  toggleMode () {
    if (this.mode === 'guest') {
      this.mode = 'respondent'
    } else {
      this.mode = 'guest'
    }
  }

  navigate () {
    const { redirect, ...query } = this.$route.query
    const formattedRedirect = typeof redirect === 'string' ? redirect : undefined
    if (formattedRedirect) {
      this.$router.push({ path: formattedRedirect, query })
    } else {
      this.$router.push({ path: '/home' })
    }
  }
}

import { render, staticRenderFns } from "./RegisterPage.vue?vue&type=template&id=718707de&scoped=true&"
import script from "./RegisterPage.vue?vue&type=script&lang=ts&"
export * from "./RegisterPage.vue?vue&type=script&lang=ts&"
import style0 from "./RegisterPage.vue?vue&type=style&index=0&id=718707de&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "718707de",
  null
  
)

export default component.exports
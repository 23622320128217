












import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Button, Card } from '@focus/components'

@Component({
  components: { Button, Card }
})
export default class NewPasswordSuccessCard extends Vue {}
























import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { Button, Card, Form, FormErrors, TextField } from '@focus/components'
import { GuestRegisterData } from '../auth.types'
import TermsAndConditionsCheckbox from '@/domains/core/components/TermsAndConditionsCheckbox.vue'

@Component({
  components: { Button, Card, TermsAndConditionsCheckbox, Form, TextField }
})
export default class GuestRegister extends Vue {
  @Prop({ type: Object, default: () => ({}) })
  private readonly errors!: FormErrors<GuestRegisterData>

  account: Partial<GuestRegisterData> = {}

  get form () {
    return this.$refs.form as Form
  }

  sameAsPassword (val: string) {
    return val === this.account.password || 'Passwords must match'
  }

  async register () {
    if (this.form.validate()) {
      this.$emit('register', this.account)
    }
  }
}

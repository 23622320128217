<template>
  <div class="d-flex" style="width: 100%; overflow: hidden; height: calc(100vh)">
    <div class="fill-height d-flex flex-column justify-center flex-shrink-1 flex-grow-1" :style="{ position: 'relative', width: '100%', 'background-image': `url(${require('@/assets/login-background.jpg')})`, 'background-size': 'cover' }">
      <div class="d-flex flex-column justify-center" style="margin-top: 64px; z-index: 10">
        <span class="white--text display-3 text-center" style="font-weight: 800">ChitChat</span>
        <span class="white--text display-2 text-center">Research</span>
      </div>
      <div class="fill-height background w-100" style="position: absolute; left: 0; right: 0; top: 0; bottom: 0;">
      </div>
      <div class="d-flex align-center justify-center mx-3 py-4">
        <Card title="Verify Account" max-width="650px" style="width: 100%;">
          <div class="pb-4">
            <div class="d-flex justify-center mb-3" style="padding-top: 50px">
              <v-avatar v-if="!loading" size="55" :color="success ? 'primary' : 'error'">
                <v-icon v-if="success" x-large color="white">check</v-icon>
                <span v-if="!success" class="white--text display-1 font-weight-medium">!</span>
              </v-avatar>
              <v-progress-circular v-if="loading" size="55" color="primary" indeterminate />
            </div>
            <div class="d-flex justify-center">
              <span v-if="loading" class="font-weight-bold">Verifying...</span>
              <span v-if="!loading" class="font-weight-bold">Verification {{ success ? 'successful' : 'failed' }}</span>
            </div>
            <div v-if="!loading" style="margin-bottom: 15px;">
              <span class="caption text-center">
                <div v-if="success">
                  <p class="mb-0">Your account was successfully verified.</p>
                  <p class="mb-0">You can now login to your account.</p>
                </div>
                <div v-if="!success">
                  <p class="mb-0">There was an error verifying your account.</p>
                  <p class="mb-0">Please contact ChitChat Research for assistance.</p>
                </div>
              </span>
            </div>
          </div>
          <div class="px-4 py-4 d-flex justify-space-between">
            <Button outlined style="min-width: 130px;" to="login">
              Back to login
            </Button>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>
<script>
import { Component } from 'vue-property-decorator'
import { ApiError, Button, Card } from '@focus/components'
import BaseComponent from '../../../plugins/mixins'

@Component({
  components: { Button, Card }
})
export default class VerifyAccountPage extends BaseComponent {
  success = false

  loading = true

  slotNumber = Number.NaN

  get token () {
    return this.$route.query.token
  }

  async mounted () {
    if (this.token) {
      try {
        const person = await this.$store.dispatch('auth/verify', { token: this.token })
        this.slotNumber = person.slotNumber
        this.success = true
      } catch (error) {
        if (error instanceof ApiError) {
          await this.showError(error)
        } else {
          throw error
        }
        this.success = false
      } finally {
        this.loading = false
      }
    } else {
      this.success = false
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .background-overlay {
    background-color: #3FBF6B;
    opacity: 0.4;
  }

  .no-transition .v-stepper__content { transition: none; }

  .background {
    background: #3FBF6B 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    opacity: 0.6;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
  }
</style>

























import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { Button, Card } from '@focus/components'

@Component({
  components: { Button, Card }
})
export default class NewPasswordErrorCard extends Vue {
  @Prop({ type: Object, default: () => null })
  readonly error!: { code: string; message: string }
}






































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { Button, Card, Form, TextField, isValidPassword } from '@focus/components'

import NewPasswordError from '../components/NewPasswordError.vue'

@Component({
  components: { Button, Card, Form, NewPasswordError, TextField }
})
export default class NewPasswordForm extends Vue {
  @Prop({ type: Boolean, default: () => false })
  readonly loading!: boolean

  @Prop({ type: Object, default: () => null })
  readonly error!: { code: string; message: string }

  password: string | null = null

  passwordConfirm: string | null = null

  get form () {
    return this.$refs.form as Form
  }

  passwordRule (val: string) {
    return isValidPassword(val)
  }

  confirmRule (val: string) {
    return val === this.password || 'Must match "Password"'
  }

  confirmPassword () {
    if (this.form.validate()) {
      this.$emit('confirm', { password: this.password })
    }
  }
}



























import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { Button, Card } from '@focus/components'

@Component({
  components: { Button, Card }
})
export default class RegisterSuccessCard extends Vue {
  @Prop()
  private readonly mode!: string
}















































import { Component } from 'vue-property-decorator'
import { ApiError, Button, Card, Form, TextField, Datepicker } from '@focus/components'
import BaseComponent from '@/plugins/mixins'

@Component({
  components: { Button, Card, Form, TextField, Datepicker }
})
export default class ForgotPasswordPage extends BaseComponent {
  email: string | null = null

  dateOfBirth: Date | null = null

  resetLinkSent = false

  get form () {
    return this.$refs.form as Form
  }

  get error () {
    return this.$store.state.auth.error
  }

  async requestReset () {
    if (this.form.validate()) {
      await this.sendResetRequest()
    }
  }

  async sendResetRequest () {
    try {
      await this.$store.dispatch('auth/requestReset', { email: this.email, dateOfBirth: this.dateOfBirth })
      this.resetLinkSent = true
    } catch (error) {
      if (error instanceof ApiError) {
        await this.showError(error)
      } else {
        throw error
      }
    }
  }
}
